// Addons
import advancedManagementRoutes from '@/addons/advancedManagement/router/routesName'
import contactRoutes from '@/addons/contact/router/routesName'
import crmRoutes from '@/addons/crm/router/routesName'
import featuredItemsRoutes from '@/addons/featuredItems/router/routesName'
import imageGalleryRoutes from '@/addons/imageGallery/router/routesName'
import manageUsersRoutes from '@/addons/manageUsers/router/routesName'
import multiPlacesRoutes from '@/addons/multiPlaces/router/routesName'
import printMenuRoutes from '@/addons/printMenu/router/routesName'
import takeAwayRoutes from '@/addons/takeAway/router/routesName'
// Constants
import { ROLES } from '@/constants'
// Layouts
const DefaultLayout = () => import('@/views/layout/DefaultLayout')
// Views
const Login = () => import('@/views/authentication/Login')
const LoginBrand = () => import('@/views/authentication/LoginBrand')
const RegisterByCompany = () => import('@/views/authentication/RegisterByCompany')
const RecoverPassword = () => import('@/views/authentication/RecoverPassword')
// Views (DefaultLayout)
const Dashboard = () => import('@/views/place/Dashboard')
const CategoriesList = () => import('@/views/categories/CategoriesList')
const DishesList = () => import('@/views/dishes/DishesList')
const UserByTabs = () => import('@/views/user/UserByTabs')
const ShareMenuLink = () => import('@/views/place/ShareMenuLink')
const ConfigMain = () => import('@/views/config/ConfigMain')
const ConfigBase = () => import('@/views/config/ConfigBase')
const ConfigWeb = () => import('@/views/config/ConfigWeb')
const ConfigMiscellaneous = () => import('@/views/config/ConfigMiscellaneous')
const RationsList = () => import('@/views/rations/RationsList')

export default [
  {
    path: '/',
    component: DefaultLayout,
    children: [
      {
        path: '/',
        name: 'dashboard',
        component: Dashboard,
        meta: {
          roles: [ROLES.horeca_admin.value, ROLES.horeca_manager.value]
        }
      },
      {
        path: '/menus',
        name: 'menus',
        component: CategoriesList,
        meta: {
          roles: [ROLES.horeca_admin.value, ROLES.horeca_manager.value]
        }
      },
      {
        path: '/categories/:id',
        name: 'categories',
        component: CategoriesList,
        meta: {
          roles: [ROLES.horeca_admin.value, ROLES.horeca_manager.value]
        }
      },
      {
        path: '/dishes/:id',
        name: 'dishes',
        component: DishesList,
        meta: {
          roles: [ROLES.horeca_admin.value, ROLES.horeca_manager.value]
        }
      },
      {
        path: '/share-menu-link',
        name: 'shareMenuLink',
        component: ShareMenuLink,
        meta: {
          roles: [ROLES.horeca_admin.value, ROLES.horeca_manager.value]
        }
      },
      {
        path: '/rations',
        name: 'rations',
        component: RationsList,
        meta: {
          roles: [ROLES.horeca_admin.value, ROLES.horeca_manager.value]
        }
      },
      // Config
      {
        path: '/config',
        name: 'config',
        component: ConfigMain,
        meta: {
          roles: [ROLES.horeca_admin.value, ROLES.horeca_manager.value]
        }
      },
      {
        path: '/config-base',
        name: 'configBase',
        component: ConfigBase,
        meta: {
          roles: [ROLES.horeca_admin.value, ROLES.horeca_manager.value]
        }
      },
      {
        path: '/config-web',
        name: 'configWeb',
        component: ConfigWeb,
        meta: {
          roles: [ROLES.horeca_admin.value, ROLES.horeca_manager.value]
        }
      },
      {
        path: '/config-miscellaneous',
        name: 'configMiscellaneous',
        component: ConfigMiscellaneous,
        meta: {
          roles: [ROLES.horeca_admin.value, ROLES.horeca_manager.value]
        }
      },

      // User
      {
        path: '/profile',
        name: 'profile',
        component: UserByTabs,
        meta: {
          roles: [ROLES.horeca_admin.value, ROLES.horeca_manager.value]
        }
      },
      // Addons
      ...advancedManagementRoutes,
      ...contactRoutes,
      ...crmRoutes,
      ...featuredItemsRoutes,
      ...imageGalleryRoutes,
      ...manageUsersRoutes,
      ...multiPlacesRoutes,
      ...printMenuRoutes,
      ...takeAwayRoutes
    ]
  },
  // Auth
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      isPublic: true
    }
  },
  {
    path: '/login-brand',
    name: 'loginBrand',
    component: LoginBrand,
    meta: {
      isPublic: true
    }
  },
  {
    path: '/recover-password',
    name: 'recoverPassword',
    component: RecoverPassword,
    meta: {
      isPublic: true
    }
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterByCompany,
    meta: {
      isPublic: true
    }
  },
  { path: '*', redirect: '/' }
]
