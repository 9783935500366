// Constants
import { BAKARTA_COOKIE, BAKARTA_COOKIE_OPTIONS } from '@/constants'
// Services
import { getIdToken } from '@/firebase/authentication'
// Cookies
import Cookies from 'js-cookie'
// Utils
import { isNil } from 'lodash'

/**
 * Creamos la cookie de sessión
 *
 * @param {string} token - token del usuario registrado
 */
export async function createSessionCookie(token = null) {
  const currentToken = isNil(token) ? await getIdToken() : token

  if (!isNil(currentToken)) {
    // Establecemos la cookie de sesión para las futuras peticiones a la API
    Cookies.set(BAKARTA_COOKIE, currentToken, BAKARTA_COOKIE_OPTIONS)
  }
}

/**
 * Obtenemos la cookie de sesión
 *
 * @return - datos de la cookie
 */
export function getSessionCookie() {
  return Cookies.get(BAKARTA_COOKIE)
}
/**
 * Refrescamos la cookie (token) de sessión (es una alias de "createSessionCookie")
 *
 * @param {string} token - token del usuario registrado
 */
export async function refreshSessionCookie(token = null) {
  await createSessionCookie(token)
  console.info('Refresco de token')
}

/**
 * Eliminamos cookie de sesión
 */
export function removeSessionCookie() {
  const cookie = Cookies.get(BAKARTA_COOKIE)

  if (!isNil(cookie)) {
    // Eliminamos cookie de local
    Cookies.remove(BAKARTA_COOKIE)
  }
}
